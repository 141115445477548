






























































import { Component, Emit, Prop, PropSync } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import Template from "@/views/setting/template.vue";

@Component({ name: 'BindLdap',
  components: { Template }
})
export default class BindPop extends VueBase {
  @Prop({ default: '' }) code?: any
  @Prop({ default: '' }) uri?: any
  @PropSync("show", { required: true }) showProp: any
  @Emit('callback') calllback() {}

	get dialogVisible() {
		return this.showProp
	}
	set dialogVisible(val: any) {
		this.showProp = val
	}

  async next() {
    if(this.hasDT===false){
      this.handleClose()
      return
    }
    this.step++
  }

  private hasDT:any = null

  private username:string = ''

  private  password:string = ''

  private step:number = 1
  private vCode:string = ''
  async handleSubmit() {
    const { status,msg } = await this.services.user.bindDongtai({
      username: this.username,
      password: this.password,
    })
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    this.$message.success(msg)
    this.handleClose()
  }

  handleClose() {
    this.dialogVisible = false
    this.calllback()
  }
}
