




















































































































































import { Component } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import emitter from './taint/Emitter'
import BindPop from '@/components/BindPop.vue'
import BindLdap from '@/components/BindLdap.vue'
import BindPopTwo from '@/components/BindPopTwo.vue'
import VerifyCode from '@/components/VerifyCode.vue'

@Component({ name: 'Login', components: { BindPop, BindLdap,BindPopTwo, VerifyCode } })
export default class Login extends VueBase {
  private ruleForm = {
    userName: '',
    password: '',
    captcha: '',
  }
  private ruleForm1 = {
    userName: '',
    password: '',
  }
  private userName = ''
  private password = ''
  // private captcha = ''
  private captcha_hash_key = ''
  private captcha_url = ''
  private login_lock = false
  private logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  private logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
  changelogo() {
    this.logo_en = '/upload/assets/img/logo_en.png?v=' + String(Math.random())
    this.logo = '/upload/assets/img/logo.png?v=' + String(Math.random())
  }
  created() {
    emitter.on('changelogo', this.changelogo)
    this.initCaptcha()
  }

  private async initCaptcha() {
    const { status, data, msg } = await this.services.user.initCaptcha()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    this.captcha_url = data['image_url']
    this.captcha_hash_key = data['hash_key']
    this.ruleForm.captcha = ''
  }

  get userInfo() {
    return this.$store.getters.userInfo
  }

  private showBindPop = false

  private showBindLdap = false
  private showUnlockPop = false
  private vCode = ''
  private isLdap = false

  private activeName = 'password'
  async handleClick(tab: any, e: any) {
    this.activeName = tab.name
    this.login_lock = false
    if (this.isLdap) {
      this.isLdap = false
    } else {
      this.isLdap = true
    }
  }
  changeLoginType() {
    this.login_lock = false
    if (this.isLdap) {
      this.isLdap = false
    } else {
      this.isLdap = true
    }
  }
  private async setUserInfo() {
    await this.$store.dispatch('user/getUserInfo')
    await this.getUser()
  }

  private showBindPopTwo = false

  private showTwoStepPop = false

  private async twoStrpClose() {
    this.showTwoStepPop = false
  }
  private async login1() {
    if (this.login_lock) {
      // return
    }

    this.login_lock = true
    let res: any = null
    let params: any = {}
    if (this.isLdap) {
      await (this.$refs.ruleForm1 as any)?.validate();
      this.userName = this.ruleForm1.userName
      this.password = this.ruleForm1.password
      params = {
        username: this.ruleForm1.userName,
        password: this.ruleForm1.password,
      }
      this.loadingStart()
      res = await this.services.user.ldapLogin(params)
    } else {
      await (this.$refs.ruleForm as any)?.validate();
      this.userName = this.ruleForm.userName
      this.password = this.ruleForm.password
      params = {
        username: this.ruleForm.userName,
        password: this.ruleForm.password,
        captcha: this.ruleForm.captcha,
        captcha_hash_key: this.captcha_hash_key,
      }
      this.loadingStart()
      res = await this.services.user.login(params)
    }
    const { status, data, msg } = res
    this.loadingDone()
    if (status === 201) {
      let lang =
        data.default_language ||
        (navigator as any).language ||
        (navigator as any).userLanguage
      lang = lang.substr(0, 2)
      await this.services.setting.setLang(lang)
      switch (lang) {
        case 'zh':
          this.$i18n.locale = 'zh_cn'
          break
        case 'en':
          this.$i18n.locale = 'en'
          break
        default:
          this.$i18n.locale = 'zh_cn'
          break
      }
      if (data.is_wait_binding) {
        this.showBindLdap = true
        return
      }
      this.setUserInfo()
      // await this.$router.push({ name: 'dashboard' })
    } else if (status === 204) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    } else if (status === 203 || status === 202) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      this.initCaptcha()
    } else if (status === 205) {
      let msg = status === 205 ? '禁用' : '锁定'
      this.$confirm(
        `<div class="j">账号已${msg}</div><div class="t">如有问题请联系管理员</div>`,
        '',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          center: true,
          showCancelButton: false,
          showConfirmButton: false,
          customClass: 'loginConfirm',
        }
      ).then()
    } else if (status === 206) {
      this.showUnlockPop = true
    }
    this.login_lock = false
  }
  private async login() {
    if (this.login_lock) {
      // return
    }

    this.login_lock = true
    let res: any = null
    let params: any = {}
    if (this.isLdap) {
      await (this.$refs.ruleForm1 as any)?.validate()
      this.userName = this.ruleForm1.userName
      this.password = this.ruleForm1.password
      params = {
        username: this.ruleForm1.userName,
        password: this.ruleForm1.password,
      }
      this.loadingStart()
      res = await this.services.user.ldapLogin(params)
    } else {
      await (this.$refs.ruleForm as any)?.validate()
      this.userName = this.ruleForm.userName
      this.password = this.ruleForm.password
      params = {
        username: this.ruleForm.userName,
        password: this.ruleForm.password,
        captcha: this.ruleForm.captcha,
        captcha_hash_key: this.captcha_hash_key,
      }
      this.loadingStart()
      res = await this.services.user.login(params)
    }
    const { status, data, msg } = res
    this.loadingDone()
    if (status === 201) {
      let lang =
        data.default_language ||
        (navigator as any).language ||
        (navigator as any).userLanguage
      lang = lang.substr(0, 2)
      await this.services.setting.setLang(lang)
      switch (lang) {
        case 'zh':
          this.$i18n.locale = 'zh_cn'
          break
        case 'en':
          this.$i18n.locale = 'en'
          break
        default:
          this.$i18n.locale = 'zh_cn'
          break
      }
      if (data.is_wait_binding) {
        this.showBindLdap = true
        return
      }
      this.setUserInfo()
      // await this.$router.push({ name: 'dashboard' })
    } else if (status === 204) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
    } else if (status === 203 || status === 202) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      this.initCaptcha()
    } else if (status === 205) {
      let msg = status === 205 ? '禁用' : '锁定'
      this.$confirm(
        `<div class="j">账号已${msg}</div><div class="t">如有问题请联系管理员</div>`,
        '',
        {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
          dangerouslyUseHTMLString: true,
          center: true,
          showCancelButton: false,
          showConfirmButton: false,
          customClass: 'loginConfirm',
        }
      ).then()
    } else if (status === 206) {
      this.showUnlockPop = true
    } else if (status === 211) {
      this.showTwoStepPop = true
    } else if (status === 210) {
      this.showBindPopTwo = true
    }
    this.login_lock = false
  }
  private enable_totp: any = ''
  private isAdmin: any = ''
  async getUser() {
    this.enable_totp = this.userInfo.enable_totp
    this.isAdmin = this.userInfo.role_id === 1
    if (!this.isAdmin) {
      this.go()
      return
    }
    if (this.enable_totp) {
      this.go()
      return
    }
    let loginUser = localStorage.loginUser
    if (!loginUser) {
      this.showBindPop = true
      localStorage.loginUser = this.userInfo.userid
      return
    }
    let arr = loginUser.split(',')
    if (arr.includes(String(this.userInfo.userid))) {
      this.go()
      return
    }
    this.showBindPop = true
    localStorage.loginUser = `${loginUser},${this.userInfo.userid}`
  }
  go() {
    this.$router.push({ name: 'home' })
  }
  async handleUnlock() {
    if (this.vCode.length !== 6) {
      this.$message.warning('请填写六位验证码')
      return
    }
    const { status, msg } = await this.services.user.totpLogin({
      username: this.ruleForm.userName || this.ruleForm1.userName,
      totp_code: this.vCode,
    })
    if (status !== 201) {
      this.$message.error(msg)
      return
    }
    await this.$store.dispatch('user/getUserInfo')
    await this.go()
  }
  handleClose() {
    this.vCode = ''
    this.showUnlockPop = false
  }
}
