

































import { Component, Emit, Prop, PropSync } from 'vue-property-decorator'
import VueBase from '@/VueBase'
import QRCode from 'qrcode'
import VerifyCode from '@/components/VerifyCode.vue'

@Component({ name: 'BindPop', components: { VerifyCode, }})
export default class BindPopTwo extends VueBase {
  @Prop({ default: false }) showTip?: boolean
  @Prop({ default: '' }) code?: any
  @Prop({ default: '' }) uri?: any
  @Prop({ default: '' }) username?: any
  @Prop({ default: '' }) password?: any
  @PropSync("show", { required: true }) showProp: any
  @Emit('callback') calllback() {}

	get dialogVisible() {
		if (this.showProp) {
			this.init()
		}
		return this.showProp
	}
	set dialogVisible(val: any) {
		this.showProp = val
	}

  async closeMFA(){
    this.showProp = false
  }

  async init() {
    let codeUri = this.uri
    if (!codeUri) {
      const { data, status } = await this.services.user.getTwoCode({
        username: this.username,
        password: this.password
      })
      if (status !== 201) return
      codeUri = data
    }

    this.$nextTick(() => {
      new QRCode.toDataURL(codeUri, (err: any, url: any) => {
        if (err) throw err
        let img :any = this.$refs.image || {}
        img.src = url
      });
    })

  }

  private vCode:string = ''
  async handleSubmit() {
    if (this.vCode.length !== 6) {
      this.$message.warning('请填写六位验证码')
      return
    }
    if (this.uri){
      const { status } = await this.services.user.resetMfaCode({
        totp_code: this.vCode
      })
      if (status !== 201) {
        this.$message.error('验证失败，请再次尝试')
        return
      }
      this.$message.success('重新绑定成功')
      this.handleClose()
      return
    }


    const { status } = await this.services.user.setTwoCode({
      username:this.username,
      password:this.password,
      totp_code: this.vCode
    })
    if (status !== 201) {
      this.$message.error('验证失败，请再次尝试')
      return
    }
    this.$message.success('验证成功')
    this.handleClose()
  }

  handleClose() {
    this.dialogVisible = false
    this.calllback()
  }
}
